var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-layout"},[_c('h2',{staticClass:"mb-5"},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]),_vm._v(" "),_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
var changed = ref.changed;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('div',{staticClass:"create-layout__form"},[_vm._t("form",null,null,{
              initRecord: _vm.initRecord,
              handleChange: _vm.handleChange,
              isSubmitting: _vm.loading,
              valid: valid,
              changed: changed,
              edit: false
            })],2),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_vm._t("actions",[_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v("Create")])])],2)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }